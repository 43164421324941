var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-system-admin-users"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-7"
  }, [_vm._v(" " + _vm._s(_vm.$t('AdminSecurityAccess')) + " ")])]), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm.globalErrorMessage ? _c('v-alert', {
    attrs: {
      "type": "error",
      "dismissible": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.globalErrorMessage) + " ")]) : _vm._e()], 1), _c('v-card', [_c('v-card-title', [_c('v-spacer'), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-card-text', [_c('v-data-table', {
    staticClass: "mb-5",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.adminRoles,
      "search": _vm.search,
      "custom-filter": _vm.customFilter,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      },
      "loading": _vm.loadingTable
    },
    scopedSlots: _vm._u([{
      key: `item.user`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'User',
              params: {
                userUUID: `${item._id}`
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item.firstname) + " - "), _c('strong', [_vm._v(_vm._s(item.lastname))])]), _c('span', [_c('br'), _vm._v(" " + _vm._s(item.email) + " ")])];
      }
    }, {
      key: `item.admin`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-switch', {
          attrs: {
            "color": "success",
            "inset": ""
          },
          on: {
            "change": _vm.roleChange
          },
          model: {
            value: item.admin,
            callback: function ($$v) {
              _vm.$set(item, "admin", $$v);
            },
            expression: "item.admin"
          }
        })];
      }
    }, {
      key: `item.access`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-switch', {
          attrs: {
            "color": "success",
            "inset": ""
          },
          on: {
            "change": _vm.roleChange
          },
          model: {
            value: item.access,
            callback: function ($$v) {
              _vm.$set(item, "access", $$v);
            },
            expression: "item.access"
          }
        })];
      }
    }], null, true)
  })], 1), _c('v-card-actions', [_c('v-row', {
    staticClass: "mb-5",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-btn', {
    staticClass: "text-none mr-1",
    attrs: {
      "color": "error",
      "disabled": !_vm.roleModified
    },
    on: {
      "click": _vm.cancelAdminAccessSecurity
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success",
      "disabled": !_vm.roleModified
    },
    on: {
      "click": _vm.submitAdminAccessSecurity
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Submit')) + " ")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }