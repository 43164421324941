<template lang="html" src="./pageSystemAdminUsers.template.vue"></template>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageSystemAdminUsers.i18n');

export default {
  name: 'PageSystemAdminUsers',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      adminRoles: [],
      adminRolesData: null,
      checked: false,
      globalErrorMessage: null,
      headers: [],
      loadingTable: false,
      roleModified: false,
      search: '',
      users: [],
    };
  },
  created() {
    this.getAdminRoles();
  },
  mounted() {
    this.headers = [
      { text: this.$t('user'), value: 'user' },
      { text: this.$t('admin'), value: 'admin' },
      { text: this.$t('access'), value: 'access' },
    ];
  },
  methods: {
    customFilter(value, search, item) {
      if (!search) return true;
      if ((item._id + ' ' + item.email + ' ' + item.firstname + ' ' + item.lastname + ' ' + item.firstname).toString().toLowerCase().includes(search)) return true;
      return CustomFilterForSearchDatatable.customFilter(value, search, this);
    },
    getAdminRoles() {
      this.loadingTable = true;
      /**
       * GET ADMIN ROLES
       */
      this.$apiInstance
        .getAdminRoles()
        .then(
          (adminRolesData) => {
            this.adminRolesData = adminRolesData;
            this.parseAdminRoles(this.adminRolesData);
          },
          (error) => {
            this.globalErrorMessage = ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loadingTable = false;
        });
    },
    parseAdminRoles(adminRolesData) {
      const adminRolesDataCopy = JSON.parse(JSON.stringify(adminRolesData));
      const adminRolesArray = [];
      for (const roleUserMapping of adminRolesDataCopy.access) {
        if (!(roleUserMapping._id in adminRolesArray)) {
          adminRolesArray[roleUserMapping._id] = roleUserMapping;
        }
        adminRolesArray[roleUserMapping._id].access = true;
      }
      for (const roleUserMapping of adminRolesDataCopy.admin) {
        if (!(roleUserMapping._id in adminRolesArray)) {
          adminRolesArray[roleUserMapping._id] = roleUserMapping;
        }
        adminRolesArray[roleUserMapping._id].admin = true;
      }
      const adminRoles = [];
      for (const userId in adminRolesArray) {
        if (!adminRolesArray[userId].access) {
          adminRolesArray[userId].access = false;
        }
        if (!adminRolesArray[userId].admin) {
          adminRolesArray[userId].admin = false;
        }
        adminRoles.push(adminRolesArray[userId]);
      }
      this.adminRoles = adminRoles;
    },
    roleChange() {
      this.roleModified = true;
    },
    submitAdminAccessSecurity() {
      const rolesUserMapping = new this.$BcmModel.Roles();
      for (const role in rolesUserMapping) {
        rolesUserMapping[role] = [];
      }
      for (const userMapping of this.adminRoles) {
        if (userMapping.access) {
          rolesUserMapping.access.push(userMapping._id);
        }
        if (userMapping.admin) {
          rolesUserMapping.admin.push(userMapping._id);
        }
      }
      const roles = new this.$BcmModel.Roles(
        rolesUserMapping.admin,
        rolesUserMapping.access
      );
      this.$apiInstance.modifyAdminRoles(roles).then(
        (data) => {
          this.adminRolesData = data;
          this.parseAdminRoles(this.adminRolesData);
          this.roleModified = false;
          /**
           * Component BeelseNotifications used
           */
          this.$notification.notify('SUCCESS',this.$t('RoleSuccessfullyModified'));
        },
        (error) => {
          this.globalErrorMessage = ApiErrorParser.parse(error);
        }
      );
    },
    cancelAdminAccessSecurity() {
      this.parseAdminRoles(this.adminRolesData);
      this.roleModified = false;
    },
  },
};
</script>
